.App {
  height: 100%;
  width: 100%;
  min-width: 100vh;
  min-height: 100vh;
}

.AppCommon {
  height: 100%;
  width: 100%;
}
.scale-hover:hover{
  transform: scale(1.1);
  transition: all .5s;
}

.cursor-pointer{
  cursor: pointer;
}

.selected{
  border: 2px solid #051355 !important;
}
